import React, { useCallback, useEffect, useState } from "react";
import CardComponent from "./CardComponent.js";

const ListCard = ({ data, onStatusChange, listUpdateOnDelete }) => {

  let isDisabled = data.risk_classification_records_count != 5 && data.aura_status === true && data.risk_calculation_status != 'success';

  if (data.risk_calculation_status == 'success' && data.sense_status == false && data.total_classifications == 0) {
    isDisabled = false
  }

  const handleListUpdateOnDelete = () => {
    listUpdateOnDelete()
  }

  const handleStatusChange = (ticketId, newStatus) => {
    onStatusChange(ticketId, newStatus);
  }

  return (
    <section>
      <div className="elementFlexVertical" style={{ minWidth: "1080px" }}>
        <div
          className="elementFlexVertical"
          style={{
            gap: 8, marginBottom: "16px",
            opacity: isDisabled ? 0.5 : 1,
            pointerEvents: isDisabled ? 'none' : 'auto',
          }}
        >
          <CardComponent
            key={data.id}
            ticket={data}
            onStatusChange={handleStatusChange}
            listUpdateOnDelete={handleListUpdateOnDelete}
          />
        </div>
      </div>
    </section>
  );
};

export default ListCard;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchVisionData, fetchTicketData } from "../components/api/api";
import LoadingPage from "../components/dashboard/LoadingPage";
import { Alert, Typography, Tabs, Divider, Button } from "antd";
import VisionMapOne from "./VisionMapOne";
import VisionMapTwo from "./VisionMapTwo";
import VisionMapThree from "./VisionMapThree";

import "leaflet/dist/leaflet.css";
import "./Reports.css";
import "./Vision.css";
import DistributionCarChart from "../components/vision/DistributionCarChart";
import CarEsgAreaChart from "../components/vision/CarEsgAreaChart";
import FieldEsgAreaChart from "../components/vision/FieldEsgAreaChart";
import VisionUpsellChart from "../components/vision/VisionUpsellChart";

import * as turf from "@turf/turf";
import { polygonStylePatterns } from "./polyStyles";
import { appToEsgId } from "./AppToEsgId";
import EsgTrackingCarChart from "../components/vision/EsgTrackingCarChart";
import EmbargoedCarChart from "../components/vision/EmbargoedCarChart";
import { PrinterFilled } from "@ant-design/icons";
import CarAreaUsage from "../components/vision/CarAreaUsage";
import VisionEsgChart from "../components/vision/VisionEsgChart";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const esgMapping = {
  1: "APP",
  5: "Área do Imóvel (SNCI)",
  6: "Área de Pousio",
  7: "Área de Topo de Morro",
  8: "Banhado",
  9: "Borda de Chapada",
  10: "Hidrografia",
  11: "Manguezal",
  12: "Nascente e Olho d'Água",
  13: "Reserva Legal",
  14: "Restinga",
  15: "Servidão Administrativa",
  16: "Área de Uso Restrito",
  17: "Vegetação Nativa",
  18: "Vereda",
  19: "Desmatamento PRODES",
  20: "Embargo ICMBio",
  21: "Embargo IBAMA",
  22: "Assentamento",
};

const VisionReport = () => {
  const { id } = useParams();
  const [ticketNumber, setTicketNumber] = useState(id || "");
  const [data, setData] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    const handleFetchTicketData = async () => {
      setTicketLoading(true);

      try {
        const response = await fetchTicketData(ticketNumber);
        if (response) {
          setTicketData(response);
        }

        setTicketLoading(false);

      } catch (err) {
        setTicketData(null);
        setError("Failed to fetch ticket data");
      }

    };

    if (ticketData === null) {
      setTicketLoading(true);
      const timeout = setTimeout(() => {
        handleFetchTicketData();
      }, 10000);

      return () => clearTimeout(timeout);
    }
  })

  useEffect(() => {
    const handleFetch = async () => {
      setLoading(true);
      try {
        const response = await fetchVisionData(ticketNumber);

        if (response && response.status !== "area_imovel_not_found") {
          setData(response);
          setError("");
        } else {
          setData({ status: "area_imovel_not_found" });
        }
      } catch (err) {
        setLoading(true);
        setRetry(retry => retry + 1)

        setData(null);

        if (retry >= 3) {
          setError("Dados da propriedade não encontrados - Por favor, contate o suporte.");
          setLoading(false);
          setData({ status: "area_imovel_not_found" });
        }

      } finally {
        setLoading(false);
      }
    };

    if (data === null && retry <= 3) {
      setLoading(true);
      const timeout = setTimeout(() => {
        handleFetch();
      }, 10000);
    }
  }, [ticketNumber, retry])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateArea = (geoJson) => {
    if (!geoJson || !geoJson.geometry || !geoJson.geometry.coordinates) return 0;
    const area = turf.area(geoJson);
    return area / 10000; // convert to hectares
  };

  let totalArea = 0;
  let consultedArea = 0;
  let consultedAreaPercentage = 0;
  let esgArea = 0;
  let esgAreaPercentage = 0;
  let identifiedArea = 0;
  let identifiedAreaPercentage = 0;
  let unclassifiedArea = 0;
  let unclassifiedAreaPercentage = 0;
  let upsellArea = 0;
  let upsellAreaPercentage = 0;
  let consultedAreaUpsellPercentage = 0;
  let esgGroups = {};
  let kmlEsgGroups = []
  let ibamaTracking = {
    overlap: 0,
    nearby: false,
  }

  if (data?.upsell_data) {
    const upsellData = data.upsell_data;

    totalArea = upsellData.total_car_area / 10000 || 0;
    consultedArea = upsellData.consulted_area / 10000 || 0;
    consultedAreaPercentage = upsellData.consulted_area_percentage || 0;
    consultedAreaUpsellPercentage = upsellData.consulted_area_upsell_percentage || 0;
    esgArea = upsellData.total_esg_area / 10000 || 0;
    esgAreaPercentage = upsellData.esg_areas_percentage || 0;
    identifiedArea = upsellData.area_identified / 10000 || 0;
    identifiedAreaPercentage = upsellData.area_identified_percentage || 0;
    unclassifiedArea = upsellData.unclassified_area / 10000 || 0;
    unclassifiedAreaPercentage = upsellData.unclassified_area_percentage || 0;
    upsellArea = upsellData.upsell_area / 10000 || 0;
    upsellAreaPercentage = upsellData.upsell_area_percentage || 0;
    esgGroups = Object.keys(upsellData.esg_areas).reduce((acc, key) => {
      const esgId = appToEsgId[key];
      const area = upsellData.esg_areas[key];
      if (!acc[esgId]) {
        acc[esgId] = {
          x: esgMapping[esgId] || `ID ${esgId}`,
          y: 0,
          color: polygonStylePatterns[esgId]?.color || "#999999",
          percentage: 0
        };
      }
      acc[esgId].y += area / 10000;
      acc[esgId].percentage += upsellData.esg_areas_percentages[`${key}_percentage`] || 0;

      return acc;
    }, {});

    if(upsellData?.kml_intersections?.kml_esg_intersections) {
      kmlEsgGroups = Object.keys(upsellData.kml_intersections.kml_esg_intersections).reduce((acc, key) => {
        const esgId = appToEsgId[key];
        const upsellEsgData = upsellData.kml_intersections.kml_esg_intersections[key];
        const area = upsellData.esg_areas[key];
        if (!acc[esgId]) {
          acc[esgId] = {
            x: esgMapping[esgId] || `ID ${esgId}`,
            y: 0,
            color: polygonStylePatterns[esgId]?.color || "#999999",
            percentage: 0
          };
        }
        acc[esgId].y += upsellEsgData.area_intersected / 10000;
        acc[esgId].percentage += upsellEsgData.percentage;

        return acc;
      }, {});
    }

    if (upsellData?.ibama_tracking) ibamaTracking.nearby = upsellData.ibama_tracking.overlap_status;
    if (kmlEsgGroups[21]) ibamaTracking.overlap = kmlEsgGroups[12].y;
  }
  else if (data?.area_imovel?.features[0]) {
    let combinedFeature = null;

    data.area_imovel.features.forEach((geojson) => {
      if (combinedFeature === null) {
        combinedFeature = geojson;
      } else {
        combinedFeature = turf.union(combinedFeature, geojson);
      }
    });

    totalArea = calculateArea(combinedFeature);

    consultedArea = data.ticket_geometry ? calculateArea(data.ticket_geometry) : 0;
    consultedAreaPercentage = 100 * consultedArea / totalArea;
    consultedAreaUpsellPercentage = 100 * consultedArea / totalArea;

    let combinedEsgFeature = null;
    if (data.esg_detections) {
      data.esg_detections.features.forEach((feature) => {
        if (combinedEsgFeature === null) {
          combinedEsgFeature = feature;
        } else {
          combinedEsgFeature = turf.union(combinedEsgFeature, feature);
        }
      });
      esgArea = calculateArea(combinedEsgFeature);
    } else {
      esgArea = 0;
    }
    esgAreaPercentage = 100 * esgArea / totalArea;

    let combinedCropScanFeature = null;
    if (data.crop_scan_detections) {
      data.crop_scan_detections.features.forEach((feature) => {
        if (!feature.geometry) {
          console.warn('Geometry CropScan is null or undefined');
          identifiedArea = 0;
          return;
        }

        let simplifiedFeature = turf.simplify(feature, { tolerance: 0.01, highQuality: false });

        if (combinedCropScanFeature === null) {
          combinedCropScanFeature = simplifiedFeature;
        } else {
          combinedCropScanFeature = turf.union(combinedCropScanFeature, simplifiedFeature);
        }


        let identifiedArea_sum = calculateArea(combinedCropScanFeature);

        identifiedArea += identifiedArea_sum
      });
    } else {
      identifiedArea = 0;
    }
    identifiedAreaPercentage = 100 * identifiedArea / totalArea;

    let unclassifiedGeometry = combinedFeature;

    if (combinedEsgFeature && combinedEsgFeature.geometry !== null) {
      unclassifiedGeometry = turf.difference(unclassifiedGeometry, combinedEsgFeature) || unclassifiedGeometry;
    }

    if (combinedCropScanFeature && combinedCropScanFeature.geometry !== null) {
      unclassifiedGeometry = turf.difference(unclassifiedGeometry, combinedCropScanFeature) || unclassifiedGeometry;
    }

    unclassifiedArea = calculateArea(unclassifiedGeometry);
    unclassifiedAreaPercentage = 100 * unclassifiedArea / totalArea;

    esgGroups = data.esg_detections.features.reduce((acc, feature) => {
      const app = feature.properties.app;
      const esgId = appToEsgId[app];
      const area = calculateArea(feature);
      if (!acc[esgId]) {
        acc[esgId] = {
          x: esgMapping[esgId] || `ID ${esgId}`,
          y: 0,
          color: polygonStylePatterns[esgId]?.color || "#999999",
          percentage: 0
        };
      }
      acc[esgId].y += area;
      acc[esgId].percentage += (100 * area / totalArea);

      return acc;
    }, {});

    upsellArea = Math.max(identifiedArea - consultedArea, upsellArea);
    upsellAreaPercentage = 100 * upsellArea / totalArea;

  }

  const handlePrint = () => {
    window.print();
  };

  const renderHeader = () => (
    <Title
      level={4}
      className="reportHeaderTitle"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div style={{marginBottom: 4}}>
        {ticketData?.farm_data?.farmer?.farmer_name || "ID do Cliente"} -{" "}
        {ticketData?.farm_data?.proposal_id || "ID da Proposta"}
      </div>
    </Title>
  );

  const renderCARCodes = () => {
    if (!data) {
      return (
        <Text style={{ fontWeight: "bold" }}>Carregando código CAR...</Text>
      );
    }

    if (!data.area_imovel || !data.area_imovel.features) {
      return (
        <Text style={{ fontWeight: "bold" }}>Código CAR Não Localizado</Text>
      );
    }

    const carCodes = data.area_imovel.features.map(
      (feature) => feature.cod_car
    );

    return (
      <Text style={{ fontWeight: "bold" }}>
        Número do CAR:{" "}
        {carCodes.map((code, index) => (
          <span key={index} style={{ color: "#9236D9" }}>
            {code}
            {index < carCodes.length - 1 ? ", " : ""}
          </span>
        ))}
      </Text>
    );
  };

  const renderText = () => (
    <div className="visionReportHeaderText" strong>
      Validado em bases de dado no dia{" "}
      {new Date(ticketData?.created_at).toLocaleDateString("pt-BR") ||
        "../../...."}
    </div>
  );

  if ((loading || error === "Still Loading") && data === null) {
    return <LoadingPage customMessages={[
      "Carregando..."
    ]} />;
  }

  return (
    <div className="reportContainer" style={{ minWidth: "1320px" }}>
      <div className="elementFlexVertical">
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <div style={{display: "flex", flexDirection: "column"}}>
            {renderHeader()}
            {renderCARCodes()}
            {renderText()}
          </div>
          <Button
            className="visionPdfButton"
            icon={<PrinterFilled />}
            type="primary"
            onClick={handlePrint}
          >
            Gerar laudo
          </Button>
        </div>
        {error && <Alert message={error} type="error" />}
        <Tabs defaultActiveKey="1" tabBarGutter={32}>
          <TabPane tab={<span className="tabText">Laudo CAR</span>} key="1">
          <div className="elementFlexVertical">
            <VisionMapTwo
              data={data}
            />
            {totalArea > 0 && (
              <div
                className="elementFlexHorizontal"
                style={{
                  height: "auto",
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FieldEsgAreaChart
                  totalArea={totalArea}
                  esgGroups={kmlEsgGroups}
                />
                <div
                  style={{
                    minHeight: "100%",
                    width: "50%",
                    minWidth: "340px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                  }}
                >
                  <CarAreaUsage
                    totalArea={totalArea}
                    consultedArea={consultedArea}
                    consultedAreaPercentage={consultedAreaPercentage}
                    esgArea={esgArea}
                    esgAreaPercentage={esgAreaPercentage}
                  />
                  <VisionEsgChart sicarOverlaps={ticketData?.risk_classification_records}/>
                </div>
              </div>
            )}
            {totalArea > 0 && (
              <div
                className="elementFlexHorizontal"
                style={{
                  height: "auto",
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CarEsgAreaChart
                  totalArea={totalArea}
                  esgGroups={esgGroups}
                />
                <div
                  style={{
                    minHeight: "100%",
                    width: "50%",
                    minWidth: "340px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                  }}
                >
                  <EsgTrackingCarChart totalArea={totalArea}/>
                  <EmbargoedCarChart data={ibamaTracking} />
                </div>
              </div>
            )}
            </div>
          </TabPane>
          <TabPane tab={<span className="tabText">Upsell</span>} key="2">
              <div className="elementFlexHorizontal">
                <VisionMapThree data={data} />
                <div className="elementFlexVertical">
                  <VisionUpsellChart
                    upsellArea={upsellArea}
                    upsellAreaPercentage={upsellAreaPercentage}
                    consultedArea={consultedArea}
                    consultedAreaUpsellPercentage={consultedAreaUpsellPercentage}
                  />
                  <DistributionCarChart
                    totalArea={totalArea}
                    identifiedArea={identifiedArea}
                    identifiedAreaPercentage={identifiedAreaPercentage}
                    consultedArea={consultedArea}
                    consultedAreaPercentage={consultedAreaPercentage}
                    esgArea={esgArea}
                    esgAreaPercentage={esgAreaPercentage}
                    unclassifiedArea={unclassifiedArea}
                    unclassifiedAreaPercentage={unclassifiedAreaPercentage}
                  />
                </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default VisionReport;

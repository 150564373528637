import React from 'react';

import "./EmbargoedCarChart.css";
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const EmbargoedCarChart = ({data}) => {

    let label = "Sem áreas próximas";
    let color = "#52C41A";

    if (data.nearby) {
        label = "Área próxima identificada"
        color = "#FAAD14"
    }

    if (data.overlap > 0) {
        label = "Área sobreposta"
        color = "#FF4D4F"
    }

    console.log(data)
    
    return (
        <div
            className="embargoedCarChartCard"
        >
            <div className='embargoedCarChartTitle'>Áreas embargadas</div>
            <div className='embargoedCarChartContent'>
                <div className='visionEmbargoedAreaDescription'>
                    <div style={{display: "flex", flexDirection: "row", gap: 8}}>
                        <div>
                            Proximidade de área de embargo IBAMA
                        </div>
                        <Tooltip
                            title="Verificamos um raio de 30km do centro do CAR"
                            overlayStyle={{ maxWidth: '180px' }}
                            overlayInnerStyle={{ textAlign: 'center' }}
                        >
                            <InfoCircleOutlined style={{cursor: "pointer"}} />
                        </Tooltip>
                    </div>
                </div>
                <div className="visionEmbargoedAreaStatus" style={{color: color}}>
                    {label}
                </div>
                {data.overlap > 0 &&
                        <div className="visionEmbargoedAreaDescription">
                            {`Área sobreposta: ${data.overlap} Ha`}
                        </div>
                }
            </div>
        </div>
    );
};

export default EmbargoedCarChart;

import {
    Row,
    Col,
    Tabs,
    Flex,
    theme,
    Steps,
    Radio,
    Modal,
    Drawer,
    Button,
    Divider,
    Typography,
} from "antd";
import {
    LeftOutlined,
    CloudOutlined,
    RightOutlined,
    PrinterOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    MenuFoldOutlined,
    DownloadOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import {useParams} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {Content} from "antd/es/layout/layout";
import "./SenseBasic.css";
import {polygonStylePatterns} from "./polyStyles";
import React, {useEffect, useState, useRef} from "react";
import "../components/dashboard/PlantingWindow.css";
import getStatus from "../components/dashboard/Status";
import LoadingPage from "../components/dashboard/LoadingPage";
import {GeoJSON, ImageOverlay, MapContainer, TileLayer} from "react-leaflet";
import ESGCardsList from "../components/dashboard/ESGCardsList";
import Notifications from "../components/dashboard/Notifications";
import AtypicalEvents from "../components/dashboard/AtypicalEvents";
import PlantingWindow from "../components/dashboard/PlantingWindow";
import formatDate from "../components/dashboard/functions/formatDate";
import generateAlerts from "../components/dashboard/functions/GenerateAlerts";
import PrecipitationChart from "../components/dashboard/PrecipitationChart";
import {fetchSenseDatedReports, fetchTicketData, fetchTrueImageDownload} from "../components/api/api";

const {TabPane} = Tabs;
const {Title, Text} = Typography;

function SenseBasicReport() {
    const {id} = useParams();
    const {token} = theme.useToken();
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [coords, setCoords] = useState(null);
    const [retry, setRetry] = useState(0);
    const [whData, setWhData] = useState([]);
    const [open, setOpen] = useState(true);
    const [current, setCurrent] = useState(0);
    const [ndviData, setNdviData] = useState([]);
    const buttonRef = useRef(null);
    const [reportId, setReportId] = useState("");
    const [selectedCase, setSelectedCase] = useState(null);
    const [realAlerts, setRealAlerts] = useState([]);
    const [reportDate, setReportDate] = useState("");
    const [originData, setOriginData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reportDates, setReportDates] = useState([]);
    const [dateZarcEnd, setDateZarcEnd] = useState("");
    const [minTempData, setMinTempData] = useState("");
    const [avgTempData, setAvgTempData] = useState("");
    const [maxTempData, setMaxTempData] = useState("");
    const [isRotated, setIsRotated] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [maxWindSpeed, setMaxWindSpeed] = useState("");
    const [senseParamsData, setSenseParamsData] = useState(null);
    const [dateZarcStart, setDateZarcStart] = useState("");
    const [formattedDate, setFormattedDate] = useState("");
    const [trueImageData, setTrueImageData] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState("a");
    const [geometryCoords, setGeometryCoords] = useState([]);
    const [areaPercentage, setAreaPercentage] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [precipitationData, setPrecipitationData] = useState([]);
    const [precipitationDate, setPrecipitationDate] = useState([]);
    const [totalPrecipitation, setTotalPrecipitation] = useState(0);
    const [estimatedSowingDate, setEstimatedSowingDate] = useState("");
    const [estimatedHarvestDate, setEstimatedHarvestDate] = useState("");
    const [buttonPosition, setButtonPosition] = useState({top: 0, right: 0});
    const [chartData, setChartData] = useState({
        series: [],
        options: {},
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ticketData, senseParams] = await Promise.all([
                    fetchTicketData(id),
                    fetchSenseDatedReports(id)
                ]);
                setData(ticketData);
                setIsLoading(false);
                setSenseParamsData(senseParams);
                setStatus(getStatus(ticketData));
                setFormattedDate(formatDate(ticketData.created_at));
                if (senseParams.results && senseParams.results.length > 0) {
                    // Extract report dates
                    const datesArray = senseParams.results.map(item => ({
                        label: new Date(item.report_date).toLocaleDateString('en-GB'),
                        key: item.id,
                        data: item,
                    }));
                    setReportDates(datesArray);
                    setSelectedReport(datesArray[0].data);
                    updateReportData(datesArray[0].data);
                    // Loop through results
                    senseParams.results.forEach(result => {
                        const precipitation = result?.weather_info?.precipitation || [];
                        setPrecipitationData(precipitation);
                        // Calculate total precipitation
                        const total = calculateTotal(precipitation);
                        setTotalPrecipitation(total);
                        // Extract harvest monitoring data (coordinates)
                        const harvestData = result?.harvest_monitoring_data;
                        if (harvestData && harvestData.features.length > 0) {
                            const validFeatures = harvestData.features.map((feature) => ({
                                type: "Feature",
                                ...feature,
                            }));
                            setGeometryCoords({
                                type: "FeatureCollection",
                                features: validFeatures,
                            });
                        } else {
                            setGeometryCoords(null);
                        }
                        // Update state variables
                        setEstimatedSowingDate(result?.plant_health.estimated_sowing_date || "");
                        setEstimatedHarvestDate(result?.plant_health.estimated_harvest_date || "");
                        setAreaPercentage(result?.area_usage?.total_sowed_area?.percentage || "");
                        setCoords([ticketData.center_coords?.lat || 0, ticketData.center_coords?.long || 0]);
                        setReportDate(result.report_date || "");
                        setReportId(result.id || "");
                        setTrueImageData(result?.true_image_data?.image_url || "");
                        setWhData([result?.true_image_data?.bbox[0][1], result?.true_image_data?.bbox[0][0]] || [])
                        setOriginData([result?.true_image_data?.bbox[1][1], result?.true_image_data?.bbox[1][0]] || [])
                        setNdviData(result.plant_health?.plant_health_graph_data || []);
                        setMaxWindSpeed(result.weather_info?.parameters_info?.max_windspeed || "");
                        setAvgTempData(result.weather_info?.parameters_info?.avg_temp || "");
                        setMaxTempData(result.weather_info?.parameters_info?.max_temp || "");
                        setMinTempData(result.weather_info?.parameters_info?.min_temp || "");
                        setPrecipitationDate(result.weather_info.dates || []);
                        setDateZarcEnd(result.zarc_estimations?.zarc_estimation_end || "");
                        setDateZarcStart(result.zarc_estimations?.zarc_estimation_start || "");
                        setRealAlerts(generateAlerts(result.notifications, ticketData.sicar_overlaps));
                    });
                }
            } catch (error) {
                setRetry(prevRetry => prevRetry + 1);
                setStatus(error.response?.status === 404 ? 'error, not found in database' : 'error');
            }
        };
        if (!data && retry === 0) {
            fetchData();
        } else if (status !== 'success') {
            const timeout = setTimeout(fetchData, 10000);
            return () => clearTimeout(timeout);
        }
    }, [data, id, retry, status]);

    const harvestMonitorintStyle = (feature) => {
        const featureValue = String(feature.properties.value);
        if (selectedCase !== null && selectedCase !== featureValue) {
            return {
                fillOpacity: 0,
                opacity: 0,
                weight: 0,
            };
        }

        // Style the features based on their value
        switch (featureValue) {
            case '0':
                return {
                    fillColor: 'orange',
                    color: 'orange',
                    weight: 2,
                    fillOpacity: 0.7,
                };
            case '1':
                return {
                    fillColor: 'green',
                    color: 'green',
                    weight: 2,
                    fillOpacity: 0.7,
                };
            default:
                return polygonStylePatterns.kmlArea;
        }
    };

    const handleReportDownload = async () => {
        if (!reportId) {
            console.error("Report ID is not available");
            return;
        }
        try {
            const {blob, headers} = await fetchTrueImageDownload(reportId);
            const contentDisposition = headers.get('content-disposition');
            let fileName = 'download.tiff';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch && fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    // Handle updating report data based on selected report
    const updateReportData = (report, ticketData) => {
        setSelectedReport(report);
        // Precipitation data and total calculation
        const precipitation = report?.weather_info?.precipitation || [];
        setPrecipitationData(precipitation);
        // Calculate total precipitation and update the state
        const total = calculateTotal(precipitation);
        setTotalPrecipitation(total);
        setCoords([ticketData?.center_coords?.lat || 0, ticketData?.center_coords?.long || 0]);
        // Set other state variables
        setEstimatedSowingDate(report?.plant_health.estimated_sowing_date || "");
        setEstimatedHarvestDate(report?.plant_health.estimated_harvest_date || "");
        setAreaPercentage(report?.area_usage?.total_sowed_area?.percentage || 0);
        setNdviData(report?.plant_health?.plant_health_graph_data || []);
        setMaxWindSpeed(report?.weather_info?.parameters_info?.max_windspeed || "");
        setAvgTempData(report?.weather_info?.parameters_info?.avg_temp || "");
        setMaxTempData(report?.weather_info?.parameters_info?.max_temp || "");
        setReportId(report?.id || "");
        setMinTempData(report?.weather_info?.parameters_info?.min_temp || "");
        setTrueImageData(report?.true_image_data?.image_url || "");
        setWhData(report?.true_image_data?.bbox[0] || [])
        setOriginData(report?.true_image_data?.bbox[1] || [])
        setPrecipitationDate(report?.weather_info?.dates || []);
        setDateZarcEnd(report?.zarc_estimations?.zarc_estimation_end || "");
        setDateZarcStart(report?.zarc_estimations?.zarc_estimation_start || "");
        setRealAlerts(generateAlerts(report?.notifications, ticketData?.sicar_overlaps || []));
    };
    console.log(senseParamsData)
    // EvolutionChart
    useEffect(() => {
            if (senseParamsData && senseParamsData.results) {
                let ndviData = senseParamsData.results[0].plant_health.plant_health_graph_data ?? [];
                // Separate avg_value and convert date to timestamps
                ndviData = ndviData.map(item => ({
                    ...item,
                    dateObj: new Date(item.date)
                })).filter(item => item.avg_value !== 0);
                ndviData.sort((a, b) => a.dateObj - b.dateObj);
                const lastDataPoint = ndviData[ndviData.length - 1];
                const lastDate = lastDataPoint.dateObj;
                const filteredData = [lastDataPoint];
                const timeThreshold = 7 * 24 * 60 * 60 * 1000; // 15 days in milliseconds
                let previousDate = lastDate;
                for (let i = ndviData.length - 2; i >= 0; i--) {
                    const currentDataPoint = ndviData[i];
                    const currentDate = currentDataPoint.dateObj;
                    if ((previousDate - currentDate) >= timeThreshold) {
                        filteredData.push(currentDataPoint);
                        previousDate = currentDate;
                    }
                }

                filteredData.reverse();
                const valuesArray = filteredData.map(item => item.avg_value);
                const datesArray = filteredData.map(item => item.dateObj.getTime());
                const firstDataPointDate = filteredData[0].dateObj;
                const lastDataPointDate = filteredData[filteredData.length - 1].dateObj;
                const reportDateObj = new Date(reportDate);
                const minDate = firstDataPointDate.getTime();
                const fifteenDaysInMs = 15 * 24 * 60 * 60 * 1000;
                let maxDate;
                if (reportDateObj > lastDataPointDate) {
                    maxDate = reportDateObj.getTime() + fifteenDaysInMs;
                } else {
                    maxDate = lastDataPointDate.getTime() + fifteenDaysInMs;
                }
                let reportDateChart = null;
                if (reportDate) {
                    const reportDateObj = new Date(reportDate);
                    if (!isNaN(reportDateObj.getTime())) {
                        reportDateObj.setDate(reportDateObj.getDate() - 15);
                        reportDateChart = reportDateObj.toISOString().split('T')[0];
                    } else {
                        console.error('Invalid reportDate:', reportDate);
                    }
                } else {
                    // Handle null or undefined reportDate
                    console.error('reportDate is null or undefined');
                    // Set reportDateChart to a default value or handle as needed
                    // reportDateChart = 'default value';
                }

                // Prepare annotations
                const annotations = [];
                annotations.push(
                    {
                        x: new Date(estimatedSowingDate).getTime(),
                        borderColor: "#b64dfe",
                        label: {
                            borderColor: "#775DD0",
                            style: {
                                fontFamily: 'Outfit',
                                fontSize: '12px',
                                color: "#ffffff",
                                background: "#775DD0",
                            },
                            text: "Data de Plantio",
                        },
                    },
                    {
                        x: new Date(estimatedHarvestDate).getTime(),
                        borderColor: "#fb8c17",
                        label: {
                            borderColor: "#775DD0",
                            style: {
                                fontFamily: 'Outfit',
                                fontSize: '12px',
                                color: "#ffffff",
                                background: "#fb8c17",
                            },
                            text: "Data de Colheita",
                        },
                    }
                );
                if (reportDateChart) {
                    annotations.push({
                        x: new Date(reportDateChart).getTime(),
                        borderColor: "#00E396",
                        label: {
                            borderColor: "#00E396",
                            style: {
                                fontFamily: 'Outfit',
                                fontSize: '12px',
                                color: "#ffffff",
                                background: "#00E396",
                            },
                            text: "Início do Monitoramento",
                        },
                    });
                }

                // Update chart data
                setChartData({
                    key: Math.random(),
                    series: [
                        {
                            name: 'NDVI',
                            data: valuesArray,
                        },
                    ],
                    options: {
                        chart: {
                            id: 'avg-ndvi-chart',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: '70%',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            type: 'datetime',
                            categories: datesArray,
                            labels: {
                                datetimeUTC: false,
                                formatter: function (value) {
                                    const date = new Date(value);
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const year = date.getFullYear();
                                    return `${day}/${month}/${year}`;
                                },
                            },
                        },
                        yaxis: {
                            min: 0.0,
                            max: 1.0,
                            tickAmount: 8,
                            labels: {
                                formatter: (value) => value.toFixed(1),
                            },
                        },
                        grid: {
                            borderColor: '#90A4AE',
                            strokeDashArray: 5,
                        },
                        tooltip: {
                            x: {
                                formatter: function (value, {dataPointIndex}) {
                                    const timestamp = datesArray[dataPointIndex];
                                    const date = new Date(timestamp);
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const year = date.getFullYear();
                                    return `${day}/${month}/${year}`;
                                },
                            },
                            y: {
                                formatter: function (val) {
                                    return `${val}`;
                                },
                            },
                        },
                        annotations: {
                            xaxis: annotations,
                        },
                    },
                });
            }
        }, [reportDate, senseParamsData]
    );
    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
    };
    const calculateTotal = (dataArray) => {
        if (!dataArray || dataArray.length === 0) return 0;
        return dataArray.reduce((acc, value) => acc + value, 0);
    };

    function convertToGeoJSON(polygonString) {
        // Remove the SRID and POLYGON part
        const coordinatesString = polygonString.replace('SRID=4326;POLYGON ((', '').replace('))', '');
        // Split the coordinates string into an array of coordinate pairs
        const coordinatesArray = coordinatesString.split(', ').map(coord => {
            const [lng, lat] = coord.split(' ').map(Number);
            return [lng, lat];
        });
        // Create the GeoJSON object
        return {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [coordinatesArray]
            },
            properties: {}
        };
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    if (isLoading) {
        return <LoadingPage/>;
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onChange = (value) => {
        setCurrent(value);
        const selected = reportDates[value]?.data;
        if (selected) {
            updateReportData(selected);
        }
    };

    const handleStepChange = (value) => {
        setCurrent(value);
        const selected = reportDates[value]?.data;
        if (selected) {
            updateReportData(selected);
        }
    };

    const handlePreviousStep = () => {
        if (current > 0) handleStepChange(current - 1);
    };

    const handleNextStep = () => {
        if (current < reportDates.length - 1) handleStepChange(current + 1);
    };

    const handleFirstStep = () => handleStepChange(0);

    const handleLastStep = () => handleStepChange(reportDates.length - 1);

    const toggleDrawer = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setButtonPosition({top: rect.bottom, right: rect.right});
        }
        setIsDrawerOpen(!isDrawerOpen);
        setOpen((prevOpen) => !prevOpen);
        setIsRotated(!isRotated);
    };

    const handlePrint = () => {
        window.print();
    };

    const onClose = () => {
        setOpen(false);
    };

    const bounds = [originData, whData];

    return (
        <div>
            <div
                style={{
                    width: '77%',
                    padding: " 0px",
                    display: "inline-flex",
                    flexDirection: "column",
                    justifyContent: "space-between",                 
                }}
            >
                {data ? (
                    <Row gutter={24} style={{
                        alignItems: "center",
                    }}>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitleSenseBasic">ID do Cliente:</p>
                                <p className="farmInfoText truncate">
                                    {data?.farm_data?.farmer?.farmer_name ? data.farm_data.farmer.farmer_name : ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Fazenda:</p>
                                <p className="farmInfoText">{data?.farm_data?.farm_name || ""}</p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Talhão:</p>
                                <p className="farmInfoText truncate">
                                    {data?.farm_data?.field_name || ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">ID da Proposta:</p>
                                <p className="farmInfoText">{data?.farm_data?.proposal_id || ""}</p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Local:</p>
                                <p className="farmInfoText">
                                    {data?.farm_data?.kml_document.kml_polygon.municipality || ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Cultura:</p>
                                <p className="farmInfoText">{data.farm_data?.crop_type}</p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Status:</p>
                                <p className="farmInfoText" style={{color: status.color}}>
                                    {status.label || ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Usuário:</p>
                                <p className="farmInfoText" style={{color: "#B64CFF"}}>
                                    {data.subwriter || ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Gerado em:</p>
                                <p className="farmInfoText">{formattedDate}</p>
                            </div>
                        </Col>
                        <Col>
                            <Button
                                className="pdfButtonSenseBasic"
                                type="primary"
                                icon={<PrinterOutlined/>}
                                onClick={handlePrint}
                            >
                                Gerar PDF
                            </Button>
                        </Col>
                        <Col 
                        justify="center"
                             align="right"
                             style={{ position: 'absolute', 
                             top: -90, 
                             width: '20%', 
                             backgroundColor: '#fafafa', 
                             right: 70, height: '300px'}}
                         >
                       <Button
                     ref={buttonRef}
                     type="primary"
                     className={"monitorReportButton"}
                     onClick={toggleDrawer}
                     style={{marginTop: '225px'}}
                         >
                      <LeftOutlined
                        style={{
                        transform: isRotated ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                         }}
                          />
                        Relatório de monitoramento
                         </Button>
                       </Col>                      
                  </Row>) : null}                  
            </div>
            <Divider/>
            <div
                style={{
                    height: "100%",
                    padding: "5px ",
                    transition: "height 0.3s ease",
                    position: "relative",
                    overflow: "hidden"
                }}
            >
                <div
                    style={{
                        height: "650px",
                        padding: "10px",
                        transition: "height 0.3s ease",
                        position: "relative",
                        width: open ? "calc(100% - 450px)" : "77%",
                        borderRadius: token.borderRadiusLG,
                        background: token.colorFillAlter,
                        border: `1px solid ${token.colorBorderSecondary}`,
                    }}
                >
                    <div className="senseBasicLegendCard">
                        <div className="senseBasicButtons">
                            <Flex vertical gap="middle">
                                <Radio.Group defaultValue="a" buttonStyle="solid" onChange={handleRadioChange}>
                                    <Radio.Button value="b">Imagem True Color</Radio.Button>
                                    <Radio.Button value="a">Área Plantada</Radio.Button>
                                </Radio.Group>
                            </Flex>
                        </div>
                        {selectedRadio !== 'b' && (
                            <div className="senseBasicLegends">
                                <div className="senseBasicLegendContent">
                                    <div className="senseBasicLegendTitle">
                                        Legendas
                                    </div>
                                    <div
                                        className="senseBasicMapLegendLine"
                                        onClick={() => setSelectedCase(selectedCase === '0' ? null : '0')}
                                    >
                                        <div
                                            className="senseBasicMapLegendSquare"
                                            style={{
                                                backgroundColor: "#52C41A",
                                                cursor: "pointer"
                                            }}
                                        />
                                        <div className="senseBasicLegendText">Área plantada</div>
                                    </div>
                                    <div
                                        className="senseBasicMapLegendLine"
                                        onClick={() => setSelectedCase(selectedCase === '1' ? null : '1')}
                                    >
                                        <div
                                            className="senseBasicMapLegendSquare"
                                            style={{
                                                backgroundColor: "#FF9709FF",
                                                cursor: "pointer"
                                            }}
                                        />
                                        <div className="senseBasicLegendText">Área não plantada</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="senseBasicDownloadReportContainer">
                            <Button
                                className="senseBasicDownloadReportButton"
                                type="primary"
                                icon={<DownloadOutlined/>}
                                onClick={handleReportDownload}
                            >
                                Baixar imagem
                            </Button>
                        </div>

                    </div>

                    <div className="senseBasicReportSelectorCard">
                        <DoubleLeftOutlined
                            className="DoubleLeftOutlined"
                            onClick={handleFirstStep}
                            disabled={current === 0}
                        />
                        <LeftOutlined
                            className="LeftOutlined"
                            onClick={handlePreviousStep}
                            disabled={current === 0}
                        />
                        <div className="datePickerSpacer"></div>

                        <Steps
                            className="custom-steps"
                            type="inline"
                            current={current}
                            onChange={onChange}
                            items={reportDates.map((item) => ({
                                title: item.label,
                                key: item.key,
                            }))}
                        />


                        <div className="datePickerSpacer"></div>
                        <RightOutlined
                            className="RightOutlined"
                            onClick={handleNextStep}
                            disabled={current === reportDates.length - 1}
                        />
                        <DoubleRightOutlined
                            className="DoubleRightOutlined"
                            onClick={handleLastStep}
                            disabled={current === reportDates.length - 1}
                        />
                    </div>

                    <MapContainer
                        center={
                            data?.center_coords && data?.center_coords?.lat && data?.center_coords?.long
                                ? [data.center_coords.lat, data.center_coords.long]
                                : [whData[1], whData[0]]
                        }
                        zoom={data?.center_coords ? 15 : 5}
                        maxZoom={16}
                        zoomControl={false}
                        dragging={false}
                        scrollWheelZoom={false}
                        doubleClickZoom={false}
                        touchZoom={false}
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"/>

                        {trueImageData && selectedRadio !== 'a' && <ImageOverlay
                            url={trueImageData} // URL for the overlay
                            bounds={whData && originData ? [whData, originData] : undefined} //Use bounds if available
                        />}

                        {geometryCoords && selectedRadio !== 'b' && (
                            <GeoJSON
                                data={geometryCoords}
                                style={harvestMonitorintStyle}
                            />
                        )}

                        {geometryCoords && selectedRadio !== 'a' && data?.farm_data?.kml_document?.kml_polygon?.geometry && (
                            <GeoJSON
                                data={convertToGeoJSON(data?.farm_data?.kml_document?.kml_polygon?.geometry)}
                                style={polygonStylePatterns["fieldArea"]}
                            />
                        )}

                        {/* Fallback to farm data if harvest_monitoring_data is unavailable */}
                        {!geometryCoords && data?.farm_data?.kml_document?.kml_polygon?.geometry && (
                            <GeoJSON
                                data={convertToGeoJSON(data?.farm_data?.kml_document?.kml_polygon?.geometry)}
                                style={polygonStylePatterns["kmlArea"]}
                            />
                        )}
                    </MapContainer>
                </div>
                <Content>

                    <div
                        style={{
                            width: open ? "calc(100% - 450px)" : "77%",
                            transition: "width 0.3s ease",
                            padding: "10px",
                        }}>
                        <Row>
                            <Col span={24}>
                                <div className="evolutionChartContainer">
                                    <ReactApexChart
                                        key={Math.random()}
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="line"
                                        height={350}
                                        width="100%"
                                        display="inline-flex"
                                        style= {{overflow: "hidden"}}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>

                <Drawer
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    getContainer={false}
                    mask={false}
                    style={{
                        top: `${buttonPosition.top}px`,
                        position: "absolute",
                        right: -50,
                        top: -20,
                        height: "1040px",
                        width: "440px",
                        transform: `translateY(${open ? "0%" : "-100%"})`,
                        transition: "transform 0.3s ease-out",
                        transform: `translateX(${open ? "-5%" : "100%"})`,
                        border: "none",
                        padding: "0px",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            right: 10,
                            cursor: "pointer",
                        }}
                        onClick={toggleDrawer}
                    >
                        <CloseOutlined style={{fontSize: "16px"}}/>
                    </div>
                    <Tabs defaultActiveKey="1" centered tabBarGutter={45}>
                        <TabPane tab="Alertas" key="1">
                            <Row>
                                <Col span={24}>
                                    <AtypicalEvents notifications={[]} esg_detections={[]}/>
                                </Col>
                            </Row>

                            <Divider/>

                            <Row>
                                <Col span={24}>
                                    <PlantingWindow
                                        dateZarcStart={dateZarcStart}
                                        dateZarcEnd={dateZarcEnd}
                                        dateHarvestStart={estimatedHarvestDate}
                                        dateSowingStart={estimatedSowingDate}
                                    />
                                </Col>
                            </Row>
                            <Divider/>

                            <Row>
                                <Col span={24}>
                                    <Notifications notifications={realAlerts}/>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="Dados Quinzenais" key="2">
                            <Row
                                style={{
                                    padding: "10px",
                                    borderRadius: token.borderRadiusLG,
                                    border: `1px solid ${token.colorBorderSecondary}`,
                                }}
                            >
                                <Col span={24}>
                                    <Title
                                        style={{
                                            lineHeight: "4px",
                                            fontSize: "32px",
                                            color: "#   ",
                                            padding: "10px",
                                            paddingTop: "0px",
                                        }}
                                    >
                                        Resumo Climático
                                    </Title>

                                    <Divider/>

                                    <Row>
                                        <Col>
                                            <div className="infoSectionRainfallSum">
                                                <CloudOutlined className="iconLarge"/>
                                                <div>
                                                    <Text className="textInfoTitle">
                                                        Total de chuva dos últimos 15 dias
                                                    </Text>
                                                    <br/>
                                                    {totalPrecipitation.toFixed(2)} mm
                                                </div>
                                            </div>
                                            <div
                                                className="infoSectionMaxTemp"
                                                style={{paddingTop: "20px"}}
                                            >
                                                <img
                                                    src="/icons/brightness_5.svg"
                                                    alt="Cloud Icon"
                                                    width={45}
                                                    height={45}
                                                />
                                                <div>
                                                    <Text className="textInfoTitle">
                                                        Temperatura Máx dos últimos 15 dias
                                                    </Text>
                                                    <br/>
                                                    {maxTempData?.toFixed(2)} ºC
                                                </div>
                                            </div>

                                            <div
                                                className="infoSectionAvgTemp"
                                                style={{paddingTop: "20px"}}
                                            >
                                                <img
                                                    src="/icons/device_thermostat.svg"
                                                    alt="Cloud Icon"
                                                    width={45}
                                                    height={45}
                                                />
                                                <div>
                                                    <Text className="textInfoTitle">
                                                        Temperatura Méd dos últimos 15 dias
                                                    </Text>
                                                    <br/>
                                                    {avgTempData.toFixed(2)} ºC
                                                </div>
                                            </div>
                                            <div
                                                className="infoSectionMinTemp"
                                                style={{paddingTop: "20px"}}
                                            >
                                                <img
                                                    src="/icons/ac_unit.svg"
                                                    alt="Cloud Icon"
                                                    width={45}
                                                    height={45}
                                                />
                                                <div>
                                                    <Text className="textInfoTitle">
                                                        Temperatura Min dos últimos 15 dias
                                                    </Text>
                                                    <br/>
                                                    {minTempData.toFixed(2)} ºC
                                                </div>
                                            </div>

                                            <div
                                                className="infoSectionMaxWind "
                                                style={{paddingTop: "20px"}}
                                            >
                                                <img
                                                    src="/icons/wind_power.svg"
                                                    alt="Cloud Icon"
                                                    width={45}
                                                    height={45}
                                                />
                                                <div>
                                                    <Text className="textInfoTitle">
                                                        Máxima dos ventos
                                                    </Text>
                                                    <br/>
                                                    {maxWindSpeed.toFixed(2)} km/h
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row
                                style={{
                                    marginTop: "15px",
                                    padding: "10px",
                                    borderRadius: token.borderRadiusLG,
                                    border: `1px solid ${token.colorBorderSecondary}`,
                                }}
                            >
                                <Col span={24}>
                                    <Title
                                        style={{
                                            lineHeight: "4px",
                                            fontSize: "32px",
                                            color: "#   ",
                                            padding: "10px",
                                            paddingTop: "0px",
                                        }}
                                    >
                                        Uso da área
                                    </Title>

                                    <Divider/>

                                    <Row
                                        style={{
                                            padding: "10px",
                                            borderRadius: token.borderRadiusLG,
                                            border: `1px solid ${token.colorBorderSecondary}`,
                                        }}
                                    >
                                        <Col span={24}>
                                            <div className="infoSection">
                                                <CloudOutlined className="iconLarge"/>
                                                <div>
                                                    <Text className="textInfoTitle">
                                                        Área utilizada para plantio
                                                    </Text>
                                                    <br/>
                                                    <Text className="textInfo">
                                                        {typeof areaPercentage === 'number' && !isNaN(areaPercentage)
                                                            ? `${areaPercentage.toFixed(2)}% da área do talhão`
                                                            : 'N/A% da área do talhão'}
                                                    </Text>
                                                </div>
                                            </div>
                                            <div
                                                className="infoSection"
                                                style={{paddingTop: "15px"}}
                                            >
                                                <CloudOutlined className="iconLarge"/>
                                                <div>
                                                    <Text className="textInfoTitle">
                                                        Cultura identificada
                                                    </Text>
                                                    <br/>
                                                    <Text className="textInfo">{data.farm_data?.crop_type}</Text>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider/>
                            <Button
                                className="precipitationChartButtonSenseBasic"
                                type="primary"
                                icon={<MenuFoldOutlined/>}
                                onClick={showModal}
                            >
                                Visualizar gráfico de precipitação
                            </Button>
                            <Modal
                                open={isModalOpen}
                                onCancel={handleCancel}
                                footer={null}
                                width="60%"
                            >
                                <p>
                                    <div>
                                        <Row>
                                            <Col span={24}>
                                                <div>
                                                    <PrecipitationChart
                                                        datesArray={precipitationDate}
                                                        valuesArray={precipitationData}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </p>
                            </Modal>
                        </TabPane>

                        <TabPane tab="ESG" key="3">
                            <ESGCardsList
                                data={data.risk_classification_records}
                                isLoading={data.risk_score ? data.risk_score : null}
                                sicar_overlaps={data.sicar_overlaps}
                            />
                        </TabPane>
                    </Tabs>
                </Drawer>
            </div>
        </div>
    );
}

export default SenseBasicReport;
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import VisionErrorPopover from "./VisionErrorPopover";
import { polygonStylePatterns } from "./polyStyles";
import "./Vision.css";
import "./VisionUpsellDashboard.css";

const VisionMapThree = ({ data }) => {
  const [showAreaImovel, setShowAreaImovel] = useState(true);
  const [showEsgDetections, setShowEsgDetections] = useState(true);
  const [showTicketGeometry, setShowTicketGeometry] = useState(true);
  const [cropVisibility, setCropVisibility] = useState({});

  console.log("Arroz", data.ticket_geometry)

  function getCropName(id) {
    const mapLabelToCrop = {
      204: "soja",
      3: "milho",
      7: "arroz",
      4: "outro"
    };

    return mapLabelToCrop[id] || "outro";
  }

  useEffect(() => {
    if (data?.crop_scan_detections?.features) {
      const initialVisibility = data.crop_scan_detections.features.reduce((acc, feature) => {
        const label = feature.properties.label;
        const crop = getCropName(label);
        acc[crop] = true;
        return acc;
      }, {});

      setCropVisibility(initialVisibility);
    }
  }, [data]);

  const toggleAreaImovel = () => setShowAreaImovel(!showAreaImovel);
  const toggleEsgDetections = () => setShowEsgDetections(!showEsgDetections);
  const toggleTicketGeometry = () => setShowTicketGeometry(!showTicketGeometry);

  const toggleLayerVisibility = (crop) => {
    setCropVisibility((prev) => ({
      ...prev,
      [crop]: !prev[crop],
    }));
  };

  const groupFeaturesByLabel = (features) => {
    return features.reduce((acc, feature) => {
      if (!feature.geometry) {
        return acc;
      }

      const label = feature.properties.label;
      const crop = getCropName(label);
      if (!acc[crop]) {
        acc[crop] = [];
      }
      acc[crop].push(feature);
      return acc;
    }, {});
  };

  const cropScanDetectionsByLabel = data?.crop_scan_detections 
    ? groupFeaturesByLabel(data.crop_scan_detections.features) 
    : {};

  const UpdateLayersOrder = () => {
    const map = useMap();

    useEffect(() => {
      if (map) {
        if (showEsgDetections) {
          map.eachLayer((layer) => {
            if (layer.options && layer.options.style && layer.options.style === polygonStylePatterns["esgArea"]) {
              layer.bringToBack();
            }
          });
        }
        Object.keys(cropVisibility).forEach(crop => {
          if (cropVisibility[crop]) {
            map.eachLayer((layer) => {
              if (layer.options && layer.options.style && layer.options.style === polygonStylePatterns[`${crop}Area`]) {
                layer.bringToBack();
              }
            });
          }
        });
        if (showAreaImovel) {
          map.eachLayer((layer) => {
            if (layer.options && layer.options.style && layer.options.style === polygonStylePatterns["carArea"]) {
              layer.bringToBack();
            }
          });
        }
      }
    }, [showAreaImovel, showEsgDetections, cropVisibility, showTicketGeometry, map]);

    return null;
  };

  return (
    <div
      className="visionReportElementCard"
      style={{
        width: "100%",
        height: "1040px",
        position: "relative",
      }}
    >
      <div className="visionMapLegendCard">
        <div className="visionMapLegendTitle">Legenda</div>
        <div className="visionMapLegendContent">
          <div className="visionMapLegendLine" onClick={toggleTicketGeometry}>
            <div
              className="visionMapLegendSquare areaTalhao"
              style={{ opacity: showTicketGeometry ? 1 : 0.5, cursor: "pointer" }}
            />
            <div className="visionLegendText">Área do talhão</div>
          </div>
          <div className="visionMapLegendLine" onClick={toggleEsgDetections}>
            <div
              className="visionMapLegendSquare areaESG"
              style={{ opacity: showEsgDetections ? 1 : 0.5, cursor: "pointer" }}
            />
            <div className="visionLegendText">Área de ESG</div>
          </div>
          {Object.keys(cropScanDetectionsByLabel).map((crop) => (
            <div key={crop} className="visionMapLegendLine" onClick={() => toggleLayerVisibility(crop)}>
              <div
                className={`visionMapLegendSquare areaCrop${crop}`}
                style={{ opacity: cropVisibility[crop] ? 1 : 0.5, cursor: "pointer" }}
              />
              <div className="visionLegendText">Área de {crop} - 23/24</div>
            </div>
          ))}
          <div className="visionMapLegendLine" onClick={toggleAreaImovel}>
            <div
              className="visionMapLegendSquare areaCAR"
              style={{ opacity: showAreaImovel ? 1 : 0.5, cursor: "pointer" }}
            />
            <div className="visionLegendText">Área do CAR</div>
          </div>
        </div>
      </div>
      <div style={{ height: "100%", width: "100%", padding: "4px" }}>
        <MapContainer
          center={[data?.center_coords?.lat || -14.235, data?.center_coords?.long || -51.9253]}
          zoom={data ? 15 : 5}
          maxZoom={16}
          zoomControl={false}
          className="fullSize"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          {showAreaImovel && data?.area_imovel &&
            data.area_imovel.features.map((feature, index) => (
              <GeoJSON key={index} data={feature} style={polygonStylePatterns["carArea"]} />
            ))}
          {Object.keys(cropScanDetectionsByLabel).map((crop) => (
            cropVisibility[crop] && (
              <GeoJSON 
                key={crop} 
                data={{ type: "FeatureCollection", features: cropScanDetectionsByLabel[crop] }} 
                style={polygonStylePatterns[`${crop}Area`]}
              />
            )
          ))}
          {showEsgDetections && data?.esg_detections && (
            <GeoJSON data={data.esg_detections} style={polygonStylePatterns["esgArea"]} />
          )}
          {showTicketGeometry && data?.ticket_geometry && (
            <GeoJSON data={data.ticket_geometry} style={polygonStylePatterns["kmlAreaPink"]} />
          )}
          <UpdateLayersOrder />
        </MapContainer>
        {data?.status === "area_imovel_not_found" && (
          <div className="popoverContainer">
            <VisionErrorPopover />
          </div>
        )}
      </div>
    </div>
  );
};

export default VisionMapThree;

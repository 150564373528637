import React from 'react';

import "./CarAreaUsage.css";
import { Divider } from 'antd';

const CarAreaUsage = ({
    totalArea,
    consultedArea,
    consultedAreaPercentage,
    esgArea,
    esgAreaPercentage,
}) => {
    
    return (
        <div
            className="carAreaUsageCard"
        >
            <div className='carAreaUsageTitle'>Uso da área do CAR</div>
            <div className='carAreaUsageContent'>
                <div className='carAreaUsageLegend'>
                    <div className='carAreaUsageLegendLine'>
                        <div className='carAreaUsageLegendTitle'>
                            Área total do CAR
                        </div>
                        <div
                            style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(totalArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                    <div className='carAreaUsageLegendLine'>
                        <div className='carAreaUsageLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#D8AECE",
                                }}
                                ></div>
                            Área consultada
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {(consultedAreaPercentage).toFixed(2).replace('.', ',')}%
                        </div>
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(consultedArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                    <div className='carAreaUsageLegendLine'>
                        <div className='carAreaUsageLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#52C41A",
                                }}
                                ></div>
                            Áreas de ESG
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {(esgAreaPercentage).toFixed(2).replace('.', ',')}%
                        </div>
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(esgArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CarAreaUsage;

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Divider } from 'antd';

import "./EsgAreaChart.css";

const CarEsgAreaChart = ({ totalArea, esgGroups }) => {
    const treemapSeriesData = Object.values(esgGroups)
    let sortedData = treemapSeriesData.sort((a, b) => b.y - a.y);

    let processedData = [];
    let othersSum = 0;
    let colors = [];

    sortedData.forEach((item, index) => {
        if (index < 3 || (index === 3 && sortedData.length <= 4)) {
            processedData.push({ x: item.x, y: item.y, percentage: item.percentage });
            colors.push(item.color);
        } else {
            othersSum += item.y;
            othersPercentage += item.percentage;
        }
    });

    if (othersSum > 0) {
        processedData.push({ x: "Outros", y: othersSum });
        colors.push("#A0D911");
    }

    const treemapData = {
        series: [
            {
                data: processedData,
            },
        ],
        options: {
            chart: {
                type: "treemap",
                toolbar: {
                    show: false,
                },
            },
            colors: colors,
            zoom: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ["#fff"]
                },
            },
            plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false
                },
            },
        },
    };

    return (
        <div className="esgAreaChartCard">
            <div className='esgAreaChartTitle'>Sobreposições do CAR</div>
            <div className='esgAreaChartContent'>
                {Object.keys(esgGroups).length > 0 ? (<>
                    <div className="esgAreaChartContainer">
                        <div style={{ width: '100%', paddingTop: '100%', position: 'relative' }}>
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                <ReactApexChart
                                    options={treemapData.options}
                                    series={treemapData.series}
                                    type="treemap"
                                    width="100%"
                                    height="100%"
                                    />
                            </div>
                        </div>
                    </div>
                    <div className='esgAreaChartLegend'>
                        <div className='esgAreaChartLegendLine'>
                            <div className='esgAreaChartLegendTitle'>
                                Área total do CAR
                            </div>
                            <Divider type="vertical" />
                            <div
                                className='esgAreaChartLegendDescription'
                                style={{ minWidth: "220px", textAlign: "right" }}>
                                Porcentagem referente ao total do CAR:
                            </div>
                            <div
                                style={{ minWidth: "68px", textAlign: "right" }}>
                                {Math.floor(totalArea)} Ha
                            </div>
                        </div>
                        {processedData.map((item, index) => (
                            <div key={index} className='esgAreaChartLegendLine'>
                                <div className='esgAreaChartLegendDot'>
                                    <div
                                        style={{
                                            height: "8px",
                                            width: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: colors[index],
                                        }}
                                        ></div>
                                    {item.x}
                                </div>
                                <Divider type="vertical" />
                                <div style={{ minWidth: "220px", textAlign: "right" }}>
                                    {item.percentage < 0.1 ? "< 0,1%" : `${item.percentage.toFixed(2).replace('.', ',')}%`}
                                </div>
                                <div style={{ minWidth: "68px", textAlign: "right" }}>
                                    {item.y < 0.1 ? "< 0,1 Ha" : item.y < 10 ? `${item.y.toFixed(1).replace('.', ',')} Ha` : `${Math.floor(item.y)} Ha`}
                                </div>
                            </div>
                        ))}
                    </div>
                </>) : (
                    <div className="esgAreaChartEmptyLegend">Sem áreas de ESG no CAR</div>
                )}
            </div>
        </div>
    );
};

export default CarEsgAreaChart;

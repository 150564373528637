import React from "react";
import TicketListing from "../components/TicketListing";
import { fetchVisionTickets } from "../components/api/api";

function Vision() {
  return (
    <div>
      <TicketListing fetchTickets={fetchVisionTickets}/>
    </div>
  );
}

export default Vision;

import React from "react";
import { fetchSenseTickets } from "../components/api/api";
import TicketListing from "../components/TicketListing";

function SenseBasic() {  
  return (
    <div>
      <TicketListing fetchTickets={fetchSenseTickets}/>
    </div>
  );
}

export default SenseBasic;

import React from 'react';

import "./EsgTrackingCarChart.css";
import { Divider } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';

const EsgTrackingCarChart = ({ totalArea }) => {
    
    return (
        <div
            className="esgTrackingCarChartCard"
        >
            <div className='esgTrackingCarChartTitle'>Rastreio da área do CAR</div>
            <div className='esgTrackingCarChartContent'>
                <div className='esgTrackingCarChartDescription'>
                    Total de hectares anterior do CAR
                </div>
                <div className="esgTrackingCarChartStatus">
                    {`${Math.floor(totalArea)} Ha`}
                </div>
                <Divider style={{ margin: 8 }}/>
                <div style={{display: "flex", flexDirection: "row", gap: 4}}>
                    <div className="esgTrackingCarChartDescription">
                        Variação após atualização: 0 Ha
                    </div>
                    {/* <CaretUpOutlined style={{ color: "#52C41A" }} /> */}
                </div>
                <div className="esgTrackingCarChartDescription">
                    {`Valor atual: ${Math.floor(totalArea)} Ha`}
                </div>
            </div>
        </div>
    );
};

export default EsgTrackingCarChart;

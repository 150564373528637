import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./components/auth/AuthContext";
import RequireAuth from "./components/auth/RequireAuth";
import ErrorBoundary from "./pages/ErrorBoundary";

// Layouts
import Layout from "./components/layout/Layout";
import LayoutLogin from "./components/layout/LayoutLogin";
import LayoutSensePlus from "./components/layout/LayoutSensePlus";
import LayoutVisionDashboard from "./components/layout/LayoutVisionDashboard";
// Pages
import Login from "./pages/Login";
import Home from "./pages/Home";
import KmlUpload from "./pages/KmlUpload";
import Aura from "./pages/Aura";
import AuraReport from "./pages/AuraReport";
import SensePlus from "./pages/SensePlus";
import Sense from "./pages/Sense";
import SenseReport from "./pages/SenseReport";
import Billing from "./pages/Billing";
import Tests from "./pages/Tests";
import BugReport from "./pages/BugReport";
import Vision from "./pages/Vision";
import VisionReport from "./pages/VisionReport";
import DisableDevtool from "disable-devtool";
import UsersList from "./pages/Users";
import VisionDashboard from "./pages/VisionDashboard";
import { logPageView } from './analytics';
import SenseBasic from "./pages/SenseBasic";
import SenseBasicReport from "./pages/SenseBasicReport";

const APP_ENV = process.env.REACT_APP_BASE_URL;

function App() {
  useEffect(() => {
    logPageView();
  }, []);


  if (!APP_ENV.includes('dev')) {
    DisableDevtool({
      disableMenu: true,
      clearLog: true,
    });
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <Routes>
            {/* Public Routes */}
            <Route
              path="/login"
              element={
                <LayoutLogin>
                  <Login />
                </LayoutLogin>
              }
            />

            {/* Private Routes (Wrapped in Layout) */}
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route
              path="/home"
              element={
                <RequireAuth>
                  <Layout>
                    <Home />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/kml-upload"
              element={
                <RequireAuth>
                  <Layout>
                    <KmlUpload />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/aura"
              element={
                <RequireAuth>
                  <Layout>
                    <Aura />
                  </Layout>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <LayoutVisionDashboard>
                    <VisionDashboard />
                  </LayoutVisionDashboard>
                </RequireAuth>
              }
            />

            <Route
              path="/aura/:id"
              element={
                <RequireAuth>
                  <Layout>
                    <AuraReport />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/sense-plus"
              element={
                <RequireAuth>
                  <LayoutSensePlus>
                    <SensePlus />
                  </LayoutSensePlus>
                </RequireAuth>
              }
            />

            <Route
              path="/sense"
              element={
                <RequireAuth>
                  <Layout>
                    <Sense />
                  </Layout>
                </RequireAuth>
              }
            />
            
            <Route
              path="/sense-basic"
              element={
                <RequireAuth>
                  <Layout>
                    <SenseBasic />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/sense-basic/:id"
              element={
                <RequireAuth>
                  <Layout>
                    <SenseBasicReport />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/sense/:id"
              element={
                <RequireAuth>
                  <Layout>
                    <SenseReport />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/tests"
              element={
                <RequireAuth>
                  <Layout>
                    <Tests />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/bug-report"
              element={
                <RequireAuth>
                  <Layout>
                    <BugReport />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/billing"
              element={
                <RequireAuth>
                  <Layout>
                    <Billing />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/vision"
              element={
                <RequireAuth>
                  <Layout>
                    <Vision />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/vision/:id"
              element={
                <RequireAuth>
                  <Layout>
                    <VisionReport />
                  </Layout>
                </RequireAuth>
              }
            />

            <Route
              path="/users/"
              element={
                <RequireAuth>
                  <Layout>
                    <UsersList />
                  </Layout>
                </RequireAuth>
              }
            />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;

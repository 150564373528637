import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Tabs, Table, Spin } from 'antd';
import dayjs from 'dayjs';

import "./ConsultedHectares.css";
import { LoadingOutlined } from '@ant-design/icons';

const ConsultedHectaresGraph = ({ data, tickets, isLoading }) => {
    const [serviceType, setServiceType] = useState('Todos');
    const [aggregationType, setAggregationType] = useState('Diário');
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const serviceTab = [
        { key: 'Todos', label: 'Todos' },
        { key: 'Aura', label: 'Aura' },
        { key: 'Sense', label: 'Sense' },
    ];

    const aggregationTab = [
        { key: 'Diário', label: 'Diário' },
        { key: 'Mensal', label: 'Mensal' },
        { key: 'Semestral', label: 'Semestral' },
    ];

    const colorMap = {
        Todos: '#2F54EB',
        Aura: '#9236D9',
        Sense: '#1890FF',
    };

    useEffect(() => {
        filterAndAggregateData();
    }, [serviceType, aggregationType, data]);

    useEffect(() => {
        setTableData(aggregateAreaPerClient(tickets));
    }, [tickets]);

    const filterAndAggregateData = () => {
        let filteredData = [];
        if (serviceType === 'Aura') {
            filteredData = data.aura_tickets_total.daily_area;
        } else if (serviceType === 'Sense') {
            filteredData = data.monitoring_tickets_total.daily_area;
        } else {
            filteredData = data.total_tickets.daily_area;
        }

        if (aggregationType === 'Mensal') {
            filteredData = aggregateMonthly(filteredData);
        } else if (aggregationType === 'Semestral') {
            filteredData = aggregateSemiAnnually(filteredData);
        }
        else {
            filteredData = filteredData.slice(-30);
        }

        const formattedData = filteredData.map(item => ({
            x: aggregationType === 'Diário' ? dayjs(item.date).format("DD/MM") : item.date,
            y: Math.floor(item.total_area)
        }));

        setChartData([{ name: "Área total", data: formattedData }]);
    };

    const aggregateMonthly = (data) => {
        const monthlyAggregation = data.reduce((acc, entry) => {
            const month = dayjs(entry.date).format('MM/YYYY');
            if (!acc[month]) {
                acc[month] = 0;
            }
            acc[month] += entry.total_area;
            return acc;
        }, {});

        const allMonths = Array.from({ length: 12 }, (_, i) => dayjs().month(i).format('MM/YYYY'));

        return allMonths.map(month => ({
            date: month,
            total_area: monthlyAggregation[month] || 0
        }));
    };

    const aggregateSemiAnnually = (data) => {
        if (data.length === 0) return [];

        const semiAnnualAggregation = data.reduce((acc, entry) => {
            const year = dayjs(entry.date).format('YYYY');
            const month = dayjs(entry.date).month();
            const semester = month < 6 ? '1' : '2';
            const key = `${year} S${semester}`;
            if (!acc[key]) {
                acc[key] = 0;
            }
            acc[key] += entry.total_area;
            return acc;
        }, {});

        const allSemesters = [];
        const startYear = dayjs(data[0].date).year();
        const endYear = dayjs(data[data.length - 1].date).year();
        for (let year = startYear; year <= endYear; year++) {
            allSemesters.push(`${year} S1`, `${year} S2`);
        }

        return allSemesters.map(semester => ({
            date: semester,
            total_area: semiAnnualAggregation[semester] || 0
        }));
    };

    const aggregateAreaPerClient = (tickets) => {
        const aggregatedData = tickets.reduce((acc, ticket) => {
            const created_by = ticket.created_by;
            if (!acc[created_by]) {
                acc[created_by] = { user: created_by, area: 0 };
            }
            acc[created_by].area += ticket.area;
            return acc;
        }, {});

        return Object.values(aggregatedData).map((item, index) => ({
            key: index + 1,
            ...item
        }));
    };

    const options = {
        chart: { type: 'bar' },
        xaxis: { type: 'category' },
        title: {
            text: 'Total de hectares consultados',
            style: {
                color: 'var(--character-primary-85, rgba(0, 0, 0, 0.85))',
                fontKerning: 'none',
                fontFeatureSettings: "'liga' off",
                fontFamily: 'Outfit',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
            },
        },
        dataLabels: { enabled: false },
        colors: [colorMap[serviceType]],
    };

    const areaPerUserColumns = [
        {
            title: 'Usuário',
            dataIndex: 'user',
            key: 'user',
            onCell: () => ({ className: 'consultedHectaresTableItem' }),
            width: '70%',
        },
        {
            title: 'Área',
            dataIndex: 'area',
            key: 'area',
            render: (value) => Math.floor(value),
            onCell: () => ({ className: 'consultedHectaresTableItem' }),
            width: '30%',
            align: "center",
        },
    ];

    return (
        <div className='consultedHectaresCard'>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: "60px" }}>
                <Tabs defaultActiveKey="Todos" items={serviceTab} onChange={key => setServiceType(key)} />
                <Tabs style={{width: 212}} defaultActiveKey="Diário" items={aggregationTab} onChange={key => setAggregationType(key)} />
            </div>
            <div className="elementFlexHorizontalConsultedHectares">
                <div style={{ width: "75%", height: "100%" }}>
                    <ReactApexChart options={options} series={chartData} type="bar" height={340} />
                </div>
                <div style={{ width: "25%" }}>
                    <div className='consultedHectaresTableTitle'>
                        Hectares consultados por cliente
                    </div>
                    <Table
                        columns={areaPerUserColumns}
                        dataSource={tableData}
                        pagination={false}
                        showHeader={false}
                        scroll={{ y: 300 }}
                        loading={{
                            spinning: isLoading,
                            indicator: <Spin
                                indicator={<LoadingOutlined />}
                                style={{
                                    color: "#B64CFF",
                                }}
                            />
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConsultedHectaresGraph;
